:root {
    --primary-color: #f53e61;
    --secondary-color: #f6f2f4;
    --text-and-link-color: #444;
  }

  .coming {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    text-align: center;
    padding-top: 20px;
    height: 100vh;
  }

  .coming .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .coming .logo img {
    width: 200px; /* Adjusted logo size */
    height: auto;
    margin-bottom: 10px;
  }

  .coming .bookish-boyfriend {
    color: var(--text-and-link-color);
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .coming .coming-soon {
    font-size: 2em;
    margin: 20px 0;
  }

  .coming  .update-link {
    color: var(--text-and-link-color); /* Updated link color */
    text-decoration: none;
    border-bottom: 2px solid var(--primary-color);
  }

  @media (max-width: 768px) {
    .coming  .coming-soon {
      font-size: 1.5em;
    }
    .coming   .bookish-boyfriend {
      font-size: 20px;
    }
  }